import React from "react"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import HeaderImage from "@page-components/products/media/conversion/header/"
import DCPImage from "@page-components/products/media/conversion/dcp/"
import CCImage from "@page-components/products/media/conversion/cc/"
import DiscsImage from "@page-components/products/media/conversion/discs/"
import SubtitlesImage from "@page-components/products/media/conversion/subtitles/"
import VideoImage from "@page-components/products/media/conversion/video/"
import HEADERS from "@data/headers/"

const MediaConversion = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="features/media-conversion">
    <Header
      title="Media Conversion"
      text="Make quick work of creating different outputs and deliverables with easy, high-quality format conversions."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.files}
    />
    <Feature
      title="DCP Conversion"
      text="Mix and match audio, video, and timed text tracks to build a professional DCP in minutes that will play anywhere in the world, guaranteed. CineSend has full support for advanced DCP formats including 4K, 3D, and immersive audio standards."
      link={{
        to: "/products/files/features/dcp-creation",
        label: "Learn more about DCP Conversion"
      }}
      button={{
        to: "/products/files/features/dcp-creation#calculator",
        label: "DCP Cost Calculator",
        className: ""
      }}
      icon="convert-white"
      image={<DCPImage />}
    />
    <Feature
      title="Video Format Conversion"
      text="Easily convert between professional video formats such as ProRes, XDCAM, MPEG-TS, HEVC, or H.264. Customize your exports with a new resolution or frame-rate. High-quality standards conversions powered by industry standard Alchemist technology keeps your content looking great."
      reverse
      icon="movie-white"
      image={<VideoImage />}
    />
    <Feature
      title="Create Blu-rays &amp; DVDs"
      text="CineSend can create basic DVD and Blu-ray disc images, and you can order physical discs to be drop-shipped to where you need to send them. Your discs will look professional with full-color thermal labels."
      icon="discs-white"
      image={<DiscsImage />}
    />
    <Feature
      title="Closed Captioning &amp; Described Video"
      text="Meet broadcast and SVOD platform accessibility requirements by ordering high-quality closed caption and described video tracks on CineSend with just a few clicks."
      reverse
      icon="cc-white"
      image={<CCImage />}
    />
    <Feature
      title="Subtitles &amp; Localization"
      text="Localize your film for international markets with comprehensive subtitling and translation services in countless languages. Local translation partners make sure things make sense in the markets you’re delivering to."
      icon="subtitle-white"
      image={<SubtitlesImage />}
    />
  </Layout>
)

export default MediaConversion
